import React, { Component } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import address from '../../images/slicing/home/address.png'
import { sendContactUsMail } from '../../middleware/apis'
class ContactUsSection extends Component {
    state = {
        name: '',
        email: '',
        phoneNumber: '',
        help: '',
        message: ''
    }
    submitForm = async (event) => {
        event.preventDefault()
        const { name, email, message, phoneNumber, help } = this.state
        var values = { name, email, message, phoneNumber, help }

        await sendContactUsMail(values)
            .then((res) => {
                this.setState({
                    name: '', email: '', help: '', message: '', phoneNumber: ''
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
    handleName = (name) => {
        console.log(name)
        this.setState({ name })
    }
    handleEmail = (email) => {
        this.setState({ email })
    }
    handleNumber = (phoneNumber) => {
        this.setState({ phoneNumber })
    }
    handleHelp = (help) => {
        this.setState({ help })
    }
    handleMessage = (message) => {
        this.setState({ message })
    }
    render() {
        const { name, email, phoneNumber, help, message } = this.state
        const { styles } = this.props
        return (
            <>
                <Container className="contact-us-homepage pb-5" style={styles}>
                    <Row className="pt-5">
                        <Col sm="12" md="6">
                            <Row>
                                <Col>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div className="line"></div>
                                        <div>
                                            <h6 className="carousel-section-subheading">GET IN TOUCH</h6>
                                        </div>
                                    </div>
                                    <h2 className="sub-subheading">CONTACT WITH US</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="2" sm="2" xs="2">
                                    <img className='address-image' src={address} alt="Not Found" />
                                </Col>
                                <Col md="10" sm="10" xs="10">
                                    <h3 className="font-weight-bold contact-heading">Addresses</h3>
                                    <p className="contact-detail border-bottom pt-2">
                                        <b className="font-weight-bold">US Office</b><br />
                                        2010 N. 1st Street, Ste 470, San Jose, CA 95131
                                    </p>
                                    <p className="contact-detail border-bottom pt-2">
                                        <b className="font-weight-bold">India Offices</b><br />
                                        Building 32, G Block, Sector 63, Noida, Uttar Pradesh 201301</p>
                                    <p className="contact-detail border-bottom pt-2">Unit 221, Vardhman Complex, Sector 19, Faridabad, Haryana India 121001</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col sm="12" md="6">
                            <Container>
                                <Form onSubmit={(event) => this.submitForm(event)}>
                                    <Row>
                                        <Col md="6" sm="12" xs="12">
                                            <Form.Group controlId="formBasicName">
                                                <Form.Label>Your Name</Form.Label>
                                                <Form.Control
                                                    value={name}
                                                    onChange={(event) => this.handleName(event.target.value)}
                                                    required={true}
                                                    size="lg"
                                                    type="text"
                                                    placeholder="Your Name" />

                                            </Form.Group>
                                        </Col>
                                        <Col md="6" sm="12" xs="12">
                                            <Form.Group controlId="formBasicPhone">
                                                <Form.Label>Phone Number</Form.Label>
                                                <Form.Control
                                                    value={phoneNumber}
                                                    onChange={(event) => this.handleNumber(event.target.value)}
                                                    required={true}
                                                    size="lg"
                                                    type="tel"
                                                    placeholder="Enter Phone Number" />

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" sm="12" xs="12">
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label>Your Email</Form.Label>
                                                <Form.Control
                                                    value={email}
                                                    onChange={(event) => this.handleEmail(event.target.value)}
                                                    required={true}
                                                    size="lg"
                                                    type="email"
                                                    placeholder="Enter Your Email" />

                                            </Form.Group>
                                        </Col>
                                        <Col md="6" sm="12" xs="12">
                                            <Form.Group controlId="formBasicRequest">
                                                <Form.Label>Your Request</Form.Label>
                                                <Form.Control
                                                    value={help}
                                                    onChange={(event) => this.handleHelp(event.target.value)}
                                                    required={true}
                                                    size="lg"
                                                    type="text"
                                                    placeholder="How we can help ?" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" sm="12" xs="12">
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Message</Form.Label>
                                                <Form.Control
                                                    value={message}
                                                    onChange={(event) => this.handleMessage(event.target.value)}
                                                    required={true}
                                                    size="lg"
                                                    as="textarea"
                                                    rows="3" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="contact-form-button">
                                            <Button
                                                type="submit"
                                                className="font-weight-bold">
                                                Submit <div className="line-white"></div>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Container>
                        </Col>
                    </Row>
                </Container>

            </>
        );
    }
}

export default ContactUsSection;